import React from 'react';
import Layout from '../components/layout';

const alcatrazHydrotherapy = () => (
  <Layout>
    <h2>Alcatraz, Hydrotherapy Room</h2>
  </Layout>
);

export default alcatrazHydrotherapy;
